<template>
  <div v-if="isPermission">
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <CRow>
            <CCol class="d-flex col-9 col-md-10 col-sm-10">
              <img src="/img/icon/store.png" style="width: 32px; height:32px; margin-right: 8px;"/>
                <select
                  class="custom-select"
                  v-model="shopObjectId"
                  disabled
                >
                  <option selected>{{ $t('selectshop') }}</option>
                  <option
                    v-for="shop in shops"
                    :key="shop.objectId"
                    :value="shop.objectId"
                  >
                    {{ shop.shopName }} - {{ shop.branchName }}
                  </option>
                </select>
            </CCol>
            <CCol sm="3" md="2" col="3" >
              <router-link :to="`/report/daily`">
                <CButton block color="light"  class="align-items-center">
                  <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
                </CButton>
              </router-link>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <h2 class="col-md-10 col-sm-9 col-8 font-weight-normal">
              {{ $t('report.txtdaily') }}
            </h2>
            <div class="col-md-2 col-sm-3 col-4 text-right text-success">
              <CButton
                v-on:click="exportDailyDetailReport()"
                v-if="isExport"
                block
                color="info"
              >
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <p>{{ $t('date') }} {{ date }}</p>
              <hr />
              <DataTable
                style="white-space: nowrap;"
                :items="items"
                :fields="fields"
                :loading="loading"
                hover
                border
              >
                <template #show_details="{index}">
                  <td class="py-2 text-center">
                    <CButton
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                      @click="toggleDetails(index)"
                    >
                      {{ $t('detail') }}
                    </CButton>
                  </td>
                </template>
              </DataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getDailyReport">
          </pagination>
        </CCardBody>
      </CCard>
    </CCol>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'

export default {
  components: {
    Pagination,
    DataTable,
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users', 'isOwner']),
    isPermission() {
      return permis.findPermissionRead('report', '/report/daily')
    },
    isExport() {
      return permis.findPermissionExport('report', '/report/daily')
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    date() {
      return moment(String(this.$route.params.date)).format('DD MMMM YYYY')
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'date',
          label: this.$i18n.t('date'),
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'receiptNumber',
          label: this.$i18n.t('receiptNumber'),
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('netAmount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'discountAmount',
          label: this.$i18n.t('discount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'vatAmount',
          label: this.$i18n.t('vatAmount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'serviceCharge',
          label: this.$i18n.t('serviceCharge'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'roundingValue',
          label: this.$i18n.t('roundingValue'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'grandTotal',
          label: this.$i18n.t('grandTotal'),
          _classes: 'text-right text-dark font-weight-normal',
        },
      ]
    },
    items() {
      let data = this.data
      let detail = []

      for (let i = 0; i < data.length; i++) {
        let serviceCharge = ''
        let roundingValue = 0
        if (data[i].serviceCharge === undefined) {
          serviceCharge = '-'
        } else {
          serviceCharge = util.convertCurrency(data[i].serviceCharge)
        }
        if (data[i].roundingValue !== undefined) {
          roundingValue = data[i].roundingValue
        }

        let discount = 0
        if (data[i].discountTotal !== undefined) {
          discount = data[i].discountTotal
        }
        if (data[i].receiptStatus === 'V') {
          detail.push({
            date: moment(String(data[i].payReceiptAt)).format(
              'DD/MM/YYYY HH:mm:ss'
            ),
            receiptNumber: data[i].receiptNumber,
            netAmount: util.convertCurrency(data[i].netAmount),
            discountAmount: util.convertCurrency(discount),
            vatAmount: util.convertCurrency(data[i].vatAmount),
            serviceCharge: serviceCharge,
            roundingValue: util.convertCurrency(roundingValue),
            grandTotal: util.convertCurrency(data[i].grandTotal),
            _classes: 'text-danger',
          })
        } else if (data[i].receiptStatus === 'W') {
          detail.push({
            date: moment(String(data[i].payReceiptAt)).format(
              'DD/MM/YYYY HH:mm:ss'
            ),
            receiptNumber: data[i].receiptNumber,
            netAmount: util.convertCurrency(data[i].netAmount),
            discountAmount: util.convertCurrency(discount),
            vatAmount: util.convertCurrency(data[i].vatAmount),
            serviceCharge: serviceCharge,
            roundingValue: util.convertCurrency(roundingValue),
            grandTotal: util.convertCurrency(data[i].grandTotal),
            _classes: 'text-warning',
          })
        } else if (data[i].receiptStatus === 'A') {
          detail.push({
            date: moment(String(data[i].payReceiptAt)).format(
              'DD/MM/YYYY HH:mm:ss'
            ),
            receiptNumber: data[i].receiptNumber,
            netAmount: util.convertCurrency(data[i].netAmount),
            discountAmount: util.convertCurrency(discount),
            vatAmount: util.convertCurrency(data[i].vatAmount),
            serviceCharge: serviceCharge,
            roundingValue: util.convertCurrency(roundingValue),
            grandTotal: util.convertCurrency(data[i].grandTotal),
          })
        }
      }
      return detail
    },
  },
  created() {
    this.getDailyReport()
  },
  methods: {
    ...util,
    getDailyReport(page = 1) {
      this.loadingButton = false
      this.loading = true
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      const headers = {
        shopObjectId: shopObjectId,
      }
      const params = {
        shopObjectId: this.$route.params.shopObjectId,
        startAt: this.$route.params.date,
        endAt: this.$route.params.date,
        page: page,
        limit: 50,
      }
      axios({
        url: '/receipt/v1.0/productselldailygroupreport/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data.list[0].list
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportDailyDetailReport() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId

      const date = moment(this.$route.params.date, 'YYYY-MM-DD').format(
        'YYYY_MM_DD'
      )
      const params = {
        shopObjectId: shopObjectId,
        startAt: this.$route.params.date,
        endAt: this.$route.params.date,
      }

      const headers = {
        shopObjectId: shopObjectId,
      }
      report({
        url: '/receipt/v1.0/productselldailywithpayment/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'รายงานยอดขายสินค้าประจำวันที่' + date + '.xlsx'
          ) //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
